import React, { useState } from "react";
import axios from "axios";
import { server } from "../env";
import Loader from "../components/Loader";
import Cookies from "js-cookie";
import Alert from "../components/Alert";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loader, setLoader] = useState(null);
  const [message, setMessage] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [otp, setOtp] = useState("");
  const [isOtpStep, setIsOtpStep] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const login = async (e) => {
    e.preventDefault();

    setLoader(<Loader />);
    try {
      const response = await axios.post(server + "/api/v1/auth/admin-login", {
        username,
        password,
      });
      if (response.data.qrCode) {
        // First-time login, show QR code
        setQrCode(response.data.qrCode);
        setLoader(null);
        setIsOtpStep(true);
      } else {
        // Move to OTP verification
        setLoader(null);
        setIsOtpStep(true);
      }
    } catch (error) {
      setMessage(
        <Alert className="danger" message={error.response.data.message} />
      );
      setLoader(null);
    }
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(server + "/api/v1/auth/verifyOtp", {
        username,
        token: otp,
      });

      Cookies.set("footprint", response.data.accessToken);
      setMessage(<Alert className="success" message="Login successful!" />);
      window.location.href = "/";
      setLoader(null);
    } catch (error) {
      setMessage(<Alert className="danger" message="Invalid OTP!" />);
      setLoader(null);
    }
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src="/logo.svg"
                    alt="logo-dark"
                  />
                </a>
              </div>
              {!isOtpStep ? (
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Sign-In</h4>
                        <div className="nk-block-des">
                          <p>
                            Access the Admin panel using your username and
                            passcode.
                          </p>
                        </div>
                      </div>
                      <div className="nk-block-head-content">{message}</div>
                    </div>

                    <form onSubmit={login}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            Username
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          name="username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                          placeholder="Enter your username"
                        />
                      </div>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="password">
                            Passcode
                          </label>
                        </div>
                        <div className="form-control-wrap">
                          <span
                            className="form-icon form-icon-right "
                            onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? (
                              <em className="passcode-icon icon-show icon ni ni-eye" />
                            ) : (
                              <em className="passcode-icon icon-show icon ni ni-eye-off" />
                            )}
                          </span>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control form-control-lg"
                            id="password"
                            name="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your passcode"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          className="btn btn-lg btn-primary btn-block"
                          type="submit">
                          Sign in {loader}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="card">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h4 className="nk-block-title">Sign-In</h4>
                        <div className="nk-block-des">
                          <p>Enter OTP</p>
                        </div>
                      </div>
                      <div className="nk-block-head-content">{message}</div>
                    </div>
                    {qrCode && (
                      <div className="w-100 mb-2 mt-2 text-center">
                        <p>Scan this QR code in Google Authenticator:</p>
                        <img src={qrCode} alt="QR Code" />
                      </div>
                    )}
                    <form onSubmit={handleOtpVerification}>
                      <div className="form-group">
                        <div className="form-label-group">
                          <label className="form-label" htmlFor="default-01">
                            OTP
                          </label>
                        </div>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          placeholder="Enter OTP"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </div>

                      <div className="form-group">
                        <button
                          className="btn btn-lg btn-primary btn-block"
                          type="submit">
                          Verify OTP {loader}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
};

export default Login;
