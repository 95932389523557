import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import axios from "axios";
import { server, config } from "../env";
import Loader from "../components/Loader";
import Alert from "../components/Alert";
import { Link } from "react-router-dom";
import {
  MdAttachMoney,
  MdMoneyOff,
  MdPriceChange,
  MdRemoveRedEye,
} from "react-icons/md";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import {
  FaSearch,
  FaUserAlt,
  FaUserAltSlash,
  FaUserEdit,
} from "react-icons/fa";
import { formatDate } from "../utils";

const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({});
  const [loader, setLoader] = useState("");
  const [message, setMessage] = useState("");
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const getUsers = async (search) => {
    setLoading(true);
    let link = `/api/v1/users/read?page=${page}&limit=${limit}`;
    if (search) {
      link += `&search=${search}`;
    }
    try {
      const res = await axios.get(server + link, config);
      setUsers(res.data.users);
      setTotalPages(res.data.totalPages);
      setTotal(res.data.total);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const enableCustomPricing = async (id) => {
    await axios
      .put(server + "/api/v1/users/custom-pricing/enable/" + id, {}, config)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };
  const enableDiableUser = async (id) => {
    await axios
      .put(server + "/api/v1/users/enable-disable/" + id, {}, config)
      .then((res) => {
        getUsers();
      })
      .catch((err) => {
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });
  };
  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    getUsers(signal);

    return function cleanup() {
      abortController.abort();
    };
  }, [page, limit, search]);

  const addBalance = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      balance: e.target.balance.value,
    };

    await axios
      .post(server + "/api/v1/users/add-balance/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const removeBalance = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      balance: e.target.removebalance.value,
    };

    await axios
      .post(server + "/api/v1/users/remove-balance/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };
  const sendMessage = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      message: e.target.chat.value,
    };

    await axios
      .post(server + "/api/v1/users/sendMessage/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const updateUser = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const data = {
      firstName: e.target.username.value,
    };

    await axios
      .put(server + "/api/v1/admin/update-user-name/" + user._id, data, config)
      .then((res) => {
        setLoader("");
        setMessage(<Alert className="success" message={res.data.message} />);
        getUsers();
      })
      .catch((err) => {
        setLoader("");
        setMessage(
          <Alert className="danger" message={err.response.data.message} />
        );
      });

    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  return (
    <div className="nk-wrap">
      <Header sidebarRef={props.sidebarRef} />
      <div className="nk-content">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <h3 className="nk-block-title page-title">Users</h3>
                  </div>
                </div>
              </div>
              <div className="nk-block">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="card shadow-sm">
                      <div className="card-inner">
                        <div className="card-filter px-lg-2 ">
                          <div className="d-flex flex-column flex-lg-row justify-content-between  align-items-center">
                            {/* .search */}
                            <div className="col-lg-6 col-12 mt-3">
                              <div className="form-group mr-3 d-flex justify-content-start align-items-center ">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Search"
                                  onChange={(e) => {
                                    setSearch(e.target.value);
                                  }}
                                />
                                <button
                                  className="btn  btn-primary ml-4 "
                                  onClick={() => getUsers(search)}
                                  disabled={search.length < 3 || loading}>
                                  {loading ? (
                                    "Searching..."
                                  ) : (
                                    <FaSearch className="icon" />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-striped">
                              <thead>
                                <tr>
                                  <th>#</th>
                                  {/* <th>Username</th> */}
                                  <th>FirstName</th>
                                  <th>Chat ID</th>
                                  <th>Balance</th>
                                  <th>ApiKey</th>
                                  <th>Status</th>
                                  <th>Joined</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {users.map((user, index) => (
                                  <tr key={index}>
                                    <td>
                                      {page > 1
                                        ? (page - 1) * 10 + index + 1
                                        : index + 1}
                                    </td>
                                    {/* <td>{user.username}</td> */}
                                    <td>{user.firstName}</td>

                                    <td>{user.chatId}</td>
                                    <td>{"$ " + user.balance}</td>
                                    <td>{user.apiKey}</td>
                                    <td>
                                      {user.status ? (
                                        <span className="badge badge-success">
                                          Active
                                        </span>
                                      ) : (
                                        <span className="badge badge-danger">
                                          Inactive
                                        </span>
                                      )}
                                    </td>
                                    <td>{formatDate(user.createdAt)}</td>

                                    <td>
                                      <div className="d-flex flex-wrap align-items-center p-2">
                                        <button
                                          className="btn btn-sm btn-primary mr-2 mb-2"
                                          data-toggle="modal"
                                          data-target="#balanceadd"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Add Balance"
                                          onClick={() => {
                                            setUser(user);
                                          }}>
                                          <MdAttachMoney className="icon" />
                                        </button>

                                        <button
                                          className="btn btn-sm btn-danger mr-2 mb-2"
                                          data-toggle="modal"
                                          data-target="#balanceremove"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Remove Balance"
                                          onClick={() => {
                                            setUser(user);
                                          }}>
                                          <MdMoneyOff className="icon" />
                                        </button>
                                        <button
                                          className="btn btn-sm btn-secondary mr-2 mb-2"
                                          data-toggle="modal"
                                          data-target="#chat"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Chat with User"
                                          onClick={() => {
                                            setUser(user);
                                          }}>
                                          <em className="icon ni ni-chat-fill"></em>
                                        </button>
                                        <button
                                          className="btn btn-sm btn-info mr-2 mb-2"
                                          data-toggle="modal"
                                          data-target="#update"
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Edit User"
                                          onClick={() => {
                                            setUser(user);
                                          }}>
                                          <FaUserEdit className="icon" />
                                        </button>
                                        <Link
                                          to={
                                            "/users/customPricing/" + user._id
                                          }
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="Add Custom Pricing"
                                          className="btn btn-sm btn-warning mr-2 mb-2">
                                          <MdPriceChange className="icon" />
                                        </Link>
                                        {user.customPricingEnabled ? (
                                          <button
                                            className="btn btn-sm btn-danger mr-2 mb-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Disable Custom Pricing"
                                            onClick={() => {
                                              enableCustomPricing(user._id);
                                            }}>
                                            <FaMoneyBillTransfer className="icon" />
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-sm btn-success mr-2 mb-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Enable Custom Pricing"
                                            onClick={() => {
                                              enableCustomPricing(user._id);
                                            }}>
                                            <FaMoneyBillTransfer className="icon" />
                                          </button>
                                        )}
                                        {user.status ? (
                                          <button
                                            className="btn btn-sm btn-danger mr-2 mb-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Disable User"
                                            onClick={() => {
                                              enableDiableUser(user._id);
                                            }}>
                                            <FaUserAltSlash className="icon" />
                                          </button>
                                        ) : (
                                          <button
                                            className="btn btn-sm btn-success mr-2 mb-2"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="top"
                                            title="Enable User"
                                            onClick={() => {
                                              enableDiableUser(user._id);
                                            }}>
                                            <FaUserAlt className="icon" />
                                          </button>
                                        )}
                                        <Link
                                          to={"/users/" + user._id}
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="top"
                                          title="View Details"
                                          className="btn btn-sm btn-info mr-2 mb-2">
                                          {/* eyte button */}

                                          <MdRemoveRedEye className="icon" />
                                        </Link>
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className="d-flex justify-content-end mt-5">
                            <nav>
                              <ul className="pagination">
                                <li
                                  className={
                                    page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }>
                                  <Link
                                    className="page-link"
                                    to=""
                                    onClick={(e) => {
                                      setPage(page - 1);
                                    }}>
                                    Prev
                                  </Link>
                                </li>

                                <li
                                  className="page-item active"
                                  aria-current="page">
                                  <Link className="page-link" to="">
                                    {page}{" "}
                                    <span className="sr-only">(current)</span>
                                  </Link>
                                </li>

                                <li
                                  className={
                                    page === totalPages
                                      ? "page-item disabled"
                                      : "page-item"
                                  }>
                                  <Link
                                    className="page-link"
                                    to=""
                                    tabIndex={-1}
                                    aria-disabled={
                                      page === totalPages ? true : false
                                    }
                                    onClick={(e) => {
                                      setPage(page + 1);
                                    }}>
                                    Next
                                  </Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* add Balance */}
      <div className="modal fade" tabIndex={-1} id="balanceadd">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Balance</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={addBalance} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Balance
                  </label>

                  <input
                    type="number"
                    name="balance"
                    placeholder="Enter the amount to be added in user's balance"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Remove Balance */}
      <div className="modal fade" tabIndex={-1} id="balanceremove">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Remove Balance</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={removeBalance} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Balance
                  </label>

                  <input
                    type="number"
                    name="removebalance"
                    placeholder="Enter the amount to be removed from user's balance"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-primary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" tabIndex={-1} id="chat">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Send Message</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={sendMessage} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Message
                  </label>

                  <input
                    type="text"
                    name="chat"
                    placeholder="Enter the message to be sent"
                    className="form-control"
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-secondary" type="submit">
                    Send {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" tabIndex={-1} id="update">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Update Username</h5>
              <button className="close" data-dismiss="modal" aria-label="Close">
                <em className="icon ni ni-cross" />
              </button>
            </div>
            <div className="modal-body">
              {message}
              <form onSubmit={updateUser} className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="full-name">
                    Username
                  </label>

                  <input
                    type="text"
                    name="username"
                    placeholder="Enter the new username"
                    className="form-control"
                    value={user.firstName}
                    onChange={(e) => {
                      setUser({ ...user, firstName: e.target.value });
                    }}
                  />
                </div>

                <div className="form-group">
                  <button className="btn btn-lg btn-secondary" type="submit">
                    Update {loader}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
