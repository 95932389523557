import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { UserContext } from "../App";

const menuItems = [
  {
    path: "/",
    label: "Dashboard",
    icon: "ni-activity-round-fill",
    roles: ["admin"],
  },
  {
    path: "/orders",
    label: "Orders",
    icon: "ni-cc-fill",
    roles: ["admin", "staff"],
  },
  {
    path: "/bulk-orders",
    label: "Bulk Orders",
    icon: "ni-box-view",
    roles: ["admin", "staff"],
  },
  {
    path: "/users",
    label: "Users",
    icon: "ni-users-fill",
    roles: ["admin", "staff"],
  },
  {
    path: "/new-users",
    label: "New Users",
    icon: "ni-users-fill",
    roles: ["admin", "staff"],
  },
  {
    path: "/staff",
    label: "Staff",
    icon: "ni-user-list-fill",
    roles: ["admin"],
  },
  {
    path: "/invoices",
    label: "Invoices",
    icon: "ni-cc-alt2-fill",
    roles: ["admin", "staff"],
  },
  {
    path: "/transaction",
    label: "Transaction Logs",
    icon: "ni-tranx-fill",
    roles: ["admin"],
  },
  {
    path: "/usps",
    label: "USPS Tracking",
    icon: "ni-tag-fill",
    roles: ["admin"],
  },
  {
    path: "/scansheet",
    label: "USPS Scansheet",
    icon: "ni-tag-fill",
    roles: ["admin"],
  },
  {
    path: "/pricing",
    label: "Label Pricing",
    icon: "ni-money",
    roles: ["admin"],
  },
  { path: "/website", label: "Website", icon: "ni-globe", roles: ["admin"] },
];

const Sidebar = ({ sidebarRef }) => {
  const [tab, setTab] = useState(window.location.pathname);
  const { user } = useContext(UserContext) || {};

  useEffect(() => {
    if (user?.role === "staff") setTab("/invoices");
  }, [user]);

  return (
    <div
      className="nk-sidebar nk-sidebar-fixed is-light"
      data-content="sidebarMenu"
      ref={sidebarRef}>
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{ position: "relative" }}>
        <div className="nk-sidebar-brand w-100">
          <NavLink
            to="/"
            className="logo-link nk-sidebar-logo d-flex justify-content-center align-items-center">
            <img
              className=" logo-img"
              src="/assets/logo.png"
              alt="logo-small"
            />
          </NavLink>
        </div>
        <div style={{ position: "absolute", top: "20%", right: "1rem" }}>
          <p
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            onClick={() =>
              sidebarRef.current?.classList.toggle("nk-sidebar-active")
            }>
            <em className="icon ni ni-arrow-left" />
          </p>
        </div>
      </div>
      {/* .nk-sidebar-element */}

      <div className="nk-sidebar-element">
        <div className="nk-sidebar-content">
          <div className="nk-sidebar-menu" data-simplebar>
            <ul className="nk-menu">
              {menuItems.map(({ path, label, icon, roles }) =>
                roles.includes(user?.role) ? (
                  <li
                    key={path}
                    className={`nk-menu-item ${tab === path ? "active" : ""}`}>
                    <NavLink
                      to={path}
                      className="nk-menu-link"
                      onClick={() => setTab(path)}>
                      <span className="nk-menu-icon">
                        <em className={`icon ni ${icon}`} />
                      </span>
                      <span className="nk-menu-text">{label}</span>
                    </NavLink>
                  </li>
                ) : null
              )}
            </ul>
          </div>
        </div>
      </div>
      {/* .nk-sidebar-element */}
    </div>
  );
};

export default Sidebar;
